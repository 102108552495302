import type { ContactSearchContextType } from "./ContactSearchContextType";

const initialState: ContactSearchContextType = {
  repoContacts: [],
  userContacts: [],
  searching: false,
  removedContacts: [],
  showOrgs: true,
  showRepos: true,
  showUsers: true,
  showEmpty: false,
  hasTooManyContacts: false,
  activeContacts: false,
  leadDiscoverySearching: false,
};
export default initialState;
